// In a file, e.g., api.js
import axios from 'axios';
const baseUrl = 'https://dashboard.adaline.app';
// const baseUrl = 'http://localhost:5000';
const apiHeaders = {
  'X-Db-Path': 'Adaline_RIEKEVN'
}
const apiPaths = {
  SurveyAnswer: `${baseUrl}/Services/SurveyAnswer/Submit`,
  TempUpload: `${baseUrl}/File/SurveyUpload`,
  GetTempFile: `${baseUrl}/upload`,
}
export const transformPayload = (surveyId, result) => {
  // console.log('answer', surveyId, result);
  let email = result['email'] || '';
  const payload = {
    "SurveyId": surveyId,
    "Email": email,
    "Answers": result
  }
  return payload;
}

export const sendDataToApi = async (surveyId, result) => {
  try {
    const payloadData = transformPayload(surveyId, result);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiPaths.SurveyAnswer,
      headers: { 
          ...apiHeaders,
          'Content-Type': 'application/json', 
          'Accept': 'application/json, text/javascript, */*; q=0.01', 
          'Accept-Language': 'en-US,en;q=0.9,vi;q=0.8,und;q=0.7'
      },
      data : payloadData
    };

    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Error sending data to API:', error);
  }
};

export const uploadApi =  (formData) => {
  const headers = apiHeaders;
  return fetch(apiPaths.TempUpload, {
    method: "POST",
    headers: headers,
    body: formData
  })
    .then((response) => response.json())
    .then((data) => { 
      return {
        url: apiPaths.GetTempFile + "/" + data.TemporaryFile
      };
    });
}