import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import $ from "jquery";
import * as SurveyCore from "survey-core";
import { bootstrapdatepicker } from "surveyjs-widgets";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";


import "./index.css";
import { themeJson } from "./theme";
import { json } from "./survey";
import { sendDataToApi, uploadApi } from './api';
 
window["$"] = window["jQuery"] = $;
require("bootstrap-datepicker/dist/js/bootstrap-datepicker.js");
bootstrapdatepicker(SurveyCore);

function SurveyComponent() {
    var urlParams = new URLSearchParams(window.location.search);
    const surveyId = urlParams.get('sid') || '3gcjLn4OpWNg59';
    console.log('surveyId', surveyId);
    const survey = new Model(json);
    survey.widthMode = "responsive";
    survey.applyTheme(themeJson);
    survey.onComplete.add((sender, options) => {
        sendDataToApi(surveyId, sender.data || {})
    });
    
    survey.onAfterRenderSurvey.add((sender, options) => {
        console.log('survey', sender);
        let questionHashes = sender.questionHashes || {};
        let questions = questionHashes.valueNames || {};
        Object.keys(questions).forEach((name)=>{
            let question = questions[name][0];
            if (question.jsonObj.defaultValueExpression=='today()') {
                console.log('question', question);
                question.dateFormat = "dd/mm/yyyy";
                question.input.value = new Date();
            }
        });
    });

    survey.onUploadFiles.add((_, options) => {
        const formData = new FormData();
        options.files.forEach((file) => {
            formData.append(file.name, file);
        });
        uploadApi(formData)
        .then(response => { 
            options.callback(options.files.map(f=>{
                return {
                    file: f,
                    content: response.url
                };
            }))
        })
        .catch((error) => {
            console.error("Error: ", error);
            options.callback([], [ 'An error occurred during file upload.' ]);
        });
    });
    
    async function deleteFile(fileURL, options) {
        try {
            // const name = fileURL.split("=")[1];
            // const apiUrl = `https://api.surveyjs.io/private/Surveys/deleteTempFile?name=${name}`;
            // const response = await fetch(apiUrl, {
            //     method: "DELETE"
            // });
    
            // if (response.status === 200) {
            //     console.log(`File ${name} was deleted successfully`);
                options.callback("success");
            // } else {
            //     console.error(`Failed to delete file: ${name}`);
            //     options.callback("error");
            // }
        } catch (error) {
            console.error("Error while deleting file: ", error);
            options.callback("error");
        }
    }
    
    survey.onClearFiles.add(async (_, options) => {
        if (!options.value || options.value.length === 0)
            return options.callback("success");
        if (!options.fileName && !!options.value) {
            for (const item of options.value) {
                await deleteFile(item.content, options);
            }
        } else {
            const fileToRemove = options.value.find(
                (item) => item.name === options.fileName
            );
            if (fileToRemove) {
                await deleteFile(fileToRemove.content, options);
            } else {
                console.error(`File with name ${options.fileName} is not found`);
            }
        }
    });
    survey.data = {
    };
    return (<Survey model={survey} />);
}

export default SurveyComponent;