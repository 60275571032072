export const json = {
    "title": "People Safety Observation Checklist",
    "description": "People Safety Observation Checklist",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "text",
                    "name": "email",
                    "title": "Email/phone",
                    "isRequired": true,
                    "requiredErrorText": "Sai email/phone",
                    "maxLength": 255,
                    "placeholder": "Email/phone của bạn"
                },
                {
                    "type": "text",
                    "name": "hoTen",
                    "title": "Họ tên/Full name",
                    "isRequired": true,
                    "placeholder": "Nhập họ tên"
                },
                {
                    "type": "dropdown",
                    "name": "boPhan",
                    "title": "Bộ phận/Department",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "baoTri",
                            "text": "Bảo trì"
                        },
                        {
                            "value": "giamDoc",
                            "text": "Giám đốc"
                        },
                        {
                            "value": "keToan",
                            "text": "Kế toán"
                        },
                        {
                            "value": "kho",
                            "text": "Kho"
                        },
                        {
                            "value": "khuon",
                            "text": "Khuôn"
                        },
                        {
                            "value": "lapRap",
                            "text": "Lắp ráp"
                        },
                        {
                            "value": "nhanSu",
                            "text": "Nhân sự"
                        },
                        {
                            "value": "qc",
                            "text": "QC"
                        },
                        {
                            "value": "tapVu",
                            "text": "Tạp vụ"
                        },
                        {
                            "value": "thuMua",
                            "text": "Thu mua"
                        }
                    ],
                    "placeholder": "Chọn bộ phận"
                },
                {
                    "type": "text",
                    "name": "chucVu",
                    "title": "Chức vụ/Position",
                    "placeholder": "Chức vụ"
                },
                {
                    "name": "ngay",
                    "title": "Ngày/Date",
                    "type": "text",
                    "inputType": "date",
                    "dateFormat": "dd/mm/yyyy",
                    "defaultValueExpression": "today()"
                },
                {
                    "type": "comment",
                    "name": "khuVucMatAnToan",
                    "title": "Khu vực mất an toàn/Unsafe area",
                    "placeholder": "Khu vực mất an toàn"
                },
                {
                    "type": "comment",
                    "name": "diemKhongAnToan",
                    "title": "CÁC HÀNH VI, ĐIỂM KHÔNG AN TOÀN/UNSAFE BEHAVIORS/POINTS",
                    "placeholder": "CÁC HÀNH VI / ĐIỂM KHÔNG AN TOÀN"
                },
                {
                    "type": "comment",
                    "name": "hanhDongKhacPhuc",
                    "title": "HÀNH ĐỘNG KHẮC PHỤC/CORRECTIVE ACTION",
                    "placeholder": "Hành động khắc phục"
                },
                {
                    "type": "text",
                    "name": "nguoiChiuTrachNhiem",
                    "title": "NGƯỜI CHỊU TRÁCH NHIỆM/WHO IS RESPONSIBLE",
                    "placeholder": "Người chịu trách nhiệm"
                },
                {
                    "name": "thoiHanKhacPhuc",
                    "title": "THỜI HẠN KHẮC PHỤC/CORRECTIVE DATE",
                    "type": "text",
                    "inputType": "date",
                    "dateFormat": "dd/mm/yyyy"
                },
                {
                    "type": "dropdown",
                    "name": "tinhtrang",
                    "title": "TÌNH TRẠNG/STATUS",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "Open",
                            "text": "Mở"
                        },
                        {
                            "value": "Close",
                            "text": "Đóng"
                        }
                    ],
                    "placeholder": "Chọn tình trạng"
                },
                {
                    "type": "file",
                    "name": "hinhAnh",
                    "title": "Hình ảnh/Attachments",
                    "allowMultiple": true,
                    "storeDataAsText": false,
                    "waitForUpload": true,
                    "maxSize": 209715200,
                    "needConfirmRemoveFile": true,
                    "sourceType": "file-camera"
                },
                {
                    "type": "comment",
                    "name": "binhluan",
                    "title": "BÌNH LUẬN/COMMENTS",
                    "placeholder": "Bình luận"
                }
            ]
        }
    ],
    "headerView": "advanced"
}